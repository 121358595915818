import PageNotFound from "@/views/404.vue";

export const menuItems = [
    {path: '', component: () => import('../views/admin/Home.vue')},
    {path: 'performance', component: () => import('../views/admin/Performance.vue')},
    {path: 'warnings', component: () => import('../views/admin/Warnings.vue')},
    {path: 'user', component: () => import('../views/admin/User.vue')},
    {path: 'user/settings', component: () => import('../views/admin/Settings.vue')},
    {
        path: '*',
        name: '404',
        component: PageNotFound
    },
]
