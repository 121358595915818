import {Component, Vue} from "vue-property-decorator";

@Component
export default class NavbarMixin extends Vue {

    created() {
        this.$store.commit('showNavbar');
    }

}
