import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {BootstrapVue} from 'bootstrap-vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
// @ts-ignore
import VueNativeSock from 'vue-native-websocket'

import {
    faBarcode,
    faCheck,
    faCog,
    faImages,
    faPowerOff,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faTimes,
    faArrowsAlt,
    faBell as fasBell,
    faSpinner,
    faInfoCircle,
    faPlus,
    faStar as faStarFull,
    faStarHalfAlt,
    faFont,
    faImage,
    faTrash,
    faListUl, faEdit, faPrint, faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import './assets/style/style.scss'
import {faBell, faEye, faUser, faStar as faStarEmpty} from "@fortawesome/free-regular-svg-icons";

Vue.config.productionTip = false;

Vue.component('fa-icon', FontAwesomeIcon);
Vue.use(BootstrapVue);
if (process.env.VUE_APP_VUE_WS_DISABLED !== 'true') {
    Vue.use(VueNativeSock, 'ws://127.0.0.1:3110', {
        reconnection: true,
        store: store,
        reconnectionAttempts: 30,
        reconnectionDelay: 3000,
    });
}
Vue.config.productionTip = false;

// @ts-ignore
library.add(faImages, faUser, faCog, faPowerOff, faCheck, faSignInAlt, faSignOutAlt, faSearch, faBarcode, faEye, faTimes, faBell, fasBell, faSpinner, faInfoCircle, faPlus, faStarFull, faStarEmpty, faStarHalfAlt, faFont, faImage, faTrash, faListUl, faEdit, faPrint, faArrowsAlt, faExclamationTriangle);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
