<template>
    <div>
        <div class="scanner-container" v-if="visible">
            <div class="inner" :class="{'success' : scannedSuccess, 'disabled': disabled === true}">
                <div v-show="isLoading === false">
                    <video poster="data:image/gif,AAAA" ref="scanner"></video>
                    <div class="overlay-element"></div>
                    <div class="laser"></div>
                    <div class="statusIcon">
                        <fa-icon icon="barcode"/>&nbsp;Oskenované
                    </div>

                    <button class="tesseractButton" @click="uploadTesseractImage">
                        <fa-icon icon="font"/>
                    </button>
                    <button class="imageButton" @click="uploadQuaggaImage">
                        <fa-icon icon="image"/>
                    </button>
                </div>
            </div>
            <p v-if="error && error.length > 0" class="text-center mt-2">
                {{error}}
            </p>
            <div v-else class="form-group mt-2">
                <select name="deviceSelect" id="deviceSelect" @change="changeCamera" v-model="selectedCamera"
                        class="form-control" title="Pouzita kamera"
                        v-if="devices.length > 0 && showDeviceSelect === true">
                    <option :value="null" disabled>Predvolená kamera</option>
                    <option :value="device.deviceId" v-for="(device, key) in devices" :key="device.deviceId">
                        Kamera {{(key + 1)}} {{device.label.indexOf('front') > -1 ? ' Predna ' :
                        (device.label.indexOf('back') > -1 ? ' Zadna ' : '')}}
                    </option>
                </select>
            </div>

            <input type="file" accept="image/*" capture="camera" @change="runTesseract" ref="tesseractInput"
                   class="d-none"/>
            <input type="file" accept="image/*" @change="runManualFileQuagga" ref="quaggaManualInput"
                   class="d-none"/>

            <b-progress v-if="tesseractProgess !== false" :value="tesseractProgess" :max="1" show-progress
                        animated class="mb-2"></b-progress>

            <div class="d-none">
                ---<br>
                T: {{test}}
                <br>---<br>
            </div>

            <p v-if="showLastScannedCode === true && code" class=" mt-2">
                Posledne naskenovane: {{code}}
            </p>
        </div>
    </div>
</template>

<script>
    import {BrowserMultiFormatReader, Exception} from "@zxing/library";
    import {createWorker} from "tesseract.js";
    import Quagga from 'quagga';

    export default {
        props: ['afterScanAction', 'visible', 'disabled', 'showDeviceSelect', 'showLastScannedCode'],
        name: "stream-barcode-reader",

        data() {
            return {
                isLoading: true,
                scannedSuccess: false,
                alreadyLoaded: false,
                tesseractProgess: false,
                error: '',
                test: '',
                code: '',
                selectedCamera: null,
                stream: null,
                devices: [],
                codeReader: new BrowserMultiFormatReader()
            };
        },
        watch: {
            visible: function (newValue, oldValue) {
                if (newValue === true) {
                    setTimeout(() => {
                        this.start();
                    }, 600);
                }
            }
        },
        computed: {
            isMediaStreamAPISupported() {
                return navigator &&
                    navigator.mediaDevices &&
                    "enumerateDevices" in navigator.mediaDevices
            }
        },
        mounted() {

            const defaultCamera = localStorage.getItem('default-camera');
            if (navigator && navigator.mediaDevices !== undefined) {
                navigator.mediaDevices.getUserMedia({video: true}).then((stream) => {
                    this.stream = stream;
                    this.stream.getTracks().forEach((t => {
                        t.stop();
                    }))
                    navigator.mediaDevices.enumerateDevices().then(devices => {
                        this.test = JSON.stringify(devices);
                        if (devices !== undefined) {
                            this.devices = devices.filter(device => device.kind === 'videoinput');
                            if (defaultCamera && this.devices.some(d => d.deviceId === defaultCamera)) {
                                this.selectedCamera = defaultCamera;
                            }
                        }


                        if (this.$refs.scanner && this.visible !== false) {
                            this.start();
                            this.$refs.scanner.oncanplay = event => {
                                this.isLoading = false;
                                this.alreadyLoaded = true;
                                console.log("loaded");
                            };
                        }
                    });


                }).catch(err => {
                    this.error = err;
                    this.error = 'Media Stream API is not supported';
                });
            }

        },

        beforeDestroy() {
            try {
                this.codeReader.reset();
            } catch (e) {
                console.log(e);
            }

        },

        methods: {
            start() {
                if (this.devices.length > 0) {
                    this.codeReader.reset();
                    try {
                        this.codeReader.decodeFromVideoDevice(
                            this.selectedCamera,
                            this.$refs.scanner,
                            (result, err) => {
                                if (result && this.scannedSuccess === false && this.disabled !== true) {
                                    this.code = result.text;
                                    if (this.afterScanAction) {
                                        this.afterScanAction(result.text);
                                    }
                                    this.scannedSuccess = true;
                                    setTimeout(() => {
                                        this.scannedSuccess = false;
                                    }, 2500)
                                }
                            }
                        );
                    } catch (e) {
                        console.log(e);
                    }

                }

                /*setTimeout(() => {
                    this.$refs.scanner.srcObject.getVideoTracks()[0].applyConstraints({advanced: [{torch: true}]});
                    this.test = this.$refs.scanner.srcObject.getVideoTracks()[0].getConstraints();
                }, 2000);*/
            },
            changeCamera() {
                this.codeReader.reset();
                this.isLoading = true;
                if (navigator && navigator.mediaDevices !== undefined) {

                    this.stream.getTracks().forEach((t => {
                        t.stop();
                    }));

                    setTimeout(() => {
                        if (this.$refs.scanner && this.visible !== false) {
                            this.start();
                            this.$refs.scanner.oncanplay = event => {
                                this.isLoading = false;
                            };
                        }
                    }, 500);

                }
                localStorage.setItem('default-camera', this.selectedCamera);
            },

            uploadTesseractImage() {
                if (this.$refs.tesseractInput) {
                    this.$refs.tesseractInput.value = "";
                    this.$refs.tesseractInput.click();
                }
            },

            uploadQuaggaImage() {
                if (this.$refs.quaggaManualInput) {
                    this.$refs.quaggaManualInput.value = "";
                    this.$refs.quaggaManualInput.click();
                }
            },

            runTesseract() {

                this.tesseractProgess = 0.1;
                const worker = createWorker({
                    logger: m => {
                        if (m instanceof Object && m.status && m.status === 'recognizing text') {
                            this.tesseractProgess = m.progress;
                        }
                    },
                });
                const file = this.$refs.tesseractInput.files[0];

                (async () => {
                    await worker.load();
                    await worker.loadLanguage('eng');
                    await worker.initialize('eng');
                    await worker.setParameters({
                        tessedit_char_whitelist: '0123456789',
                        tessedit_char_whiltelist: '0123456789'
                    })
                    const {data: {text}} = await worker.recognize(file);

                    setTimeout(() => {
                        if (this.afterScanAction) {
                            this.afterScanAction(text.replace(' ', ''));
                        }
                        this.tesseractProgess = false;
                    }, 400);


                    await worker.terminate();
                })();

            },

            runManualFileQuagga() {

                function readImage(file, callback) {
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        callback(reader.result);
                    };
                    reader.readAsDataURL(file);
                }

                const file = this.$refs.quaggaManualInput.files[0];

                readImage(file, (data) => {
                    Quagga.decodeSingle({
                        decoder: {
                            readers: ["ean_reader", "code_128_reader", "codabar_reader", "ean_8_reader"] // List of active readers
                        },
                        locate: true, // try to locate the barcode in the image
                        src: data // or 'data:image/jpg;base64,' + data
                    }, (result) => {
                        if (result && result.codeResult) {
                            this.afterScanAction(result.codeResult.code);
                        } else {
                            this.afterScanAction("not detected");
                        }
                    });
                });


            }
        }
    };
</script>

<style scoped lang="scss">

    @import "@/assets/style/_variables";

    .scanner-container > .inner {
        max-width: 380px;
        position: relative;
        border: 4px solid $baseColor;
        border-radius: 15px;
        text-align: center;
        margin: auto;

        video {
            width: 100%;
            height: 100%;
            max-width: 380px;
            margin: auto;
            max-height: 250px;
            object-fit: cover;
            object-position: top;
            border-radius: 12px;
        }

        .imageButton {
            position: absolute;
            right: 15px;
            bottom: 65px;
            box-shadow: none;
            border: none;
            background: $baseColor;
            color: black;
            width: 32px;
            height: 32px;
            border-radius: 100%;
            outline: none !important;
        }

        .tesseractButton {
            position: absolute;
            right: 15px;
            bottom: 15px;
            background: $baseColor;
            box-shadow: none;
            border: none;
            color: black;
            width: 32px;
            height: 32px;
            border-radius: 100%;
            outline: none !important;
        }

        &.disabled {
            .statusIcon {
                transition: none;
                background: #a53f3f;
            }

            border: 4px solid #a53f3f;
        }

        .overlay-element {
            border-radius: 12px;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(30, 30, 30, 0.5);

            -webkit-clip-path: polygon(
                            0% 0%,
                            0% 100%,
                            7% 100%,
                            7% 7%,
                            93% 7%,
                            93% 93%,
                            7% 93%,
                            7% 100%,
                            100% 100%,
                            100% 0%
            );
            clip-path: polygon(
                            0% 0%,
                            0% 100%,
                            7% 100%,
                            7% 7%,
                            93% 7%,
                            93% 93%,
                            7% 93%,
                            7% 100%,
                            100% 100%,
                            100% 0%
            );
        }

        .statusIcon {
            transition: all 0.4s ease-out;
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            background: $secondColor;
            left: 0;
            right: 0;
            vertical-align: middle;
            color: white;
            font-size: 18px;
            padding: 10px 10px 5px 15px;
            text-align: center;
            height: 48px;
            width: 165px;
            text-align: center;
            margin: auto;
            border-radius: 30px;
            border: 2px solid white;

            svg {
                font-size: 24px;
                float: left;
            }
        }

        .laser {
            width: 90%;
            margin-left: 5%;
            background-color: $baseColor;
            height: 2px;
            position: absolute;
            top: 20%;
            z-index: 2;
            box-shadow: 0 0 4px $baseColor;
            -webkit-animation: scanning 2s infinite;
            animation: scanning 2s infinite;
        }

        &.success {
            border: 4px solid $secondColor;

            .laser {
                display: none;
            }

            .statusIcon {
                opacity: 1;
            }
        }

    }


    @-webkit-keyframes scanning {
        50% {
            -webkit-transform: translateY(150px);
            transform: translateY(150px);
        }
    }

    @keyframes scanning {
        50% {
            -webkit-transform: translateY(150px);
            transform: translateY(150px);
        }
    }
</style>
