import {Component, Vue} from "vue-property-decorator";
import {TyrionApiResponse} from "@/lib/interfaces/TyrionApiResponse";
import axios, {AxiosPromise, AxiosRequestConfig, AxiosInstance} from "axios";

@Component
export default class TyrionApi extends Vue {


    callTyrionUrl(url: string, visitorKey?: string | null): AxiosPromise<TyrionApiResponse> {
        return axios.get(`${process.env.VUE_APP_API_URL}${url}`, this.axiosTyrionHeaders(visitorKey));
    }

    postTyrionUrl(url: string, data: {}, visitorKey?: string | null): AxiosPromise<TyrionApiResponse> {
        return axios.post(`${process.env.VUE_APP_API_URL}${url}`, data, this.axiosTyrionHeaders(visitorKey));
    }

    axiosTyrionHeaders(visitorKey?: string | null): AxiosRequestConfig {
        return {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Authentication": `token:${process.env.VUE_APP_API_TOKEN}${Math.round(+new Date() / 1000)}000$g2${Math.floor((Math.random() * 2222) + 2222)}000$g2${visitorKey !== null && visitorKey !== undefined ? visitorKey : ""}`
            }
        };
    }

}
