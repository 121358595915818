// @ts-nocheck
import axios, {AxiosResponse} from "axios";
import {TyrionApiResponse} from "@/lib/interfaces/TyrionApiResponse";
import User from "@/lib/models/User";


const state = {
    isConnected: false,
    latestMessage: '',
    reconnectError: false,
    scannedBarcode: {code: null, type: null}
};

const mutations = {
    SOCKET_ONOPEN(state, event) {
        state.datawedge.isConnected = true
    },
    SOCKET_ONCLOSE(state, event) {
        state.datawedge.isConnected = false
    },
    SOCKET_ONERROR(state, event) {
        console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
        state.datawedge.latestMessage = message.data;

        let msg = message.data; // "Barcode (974154145178484) [LABEL-TYPE-EAN13]" Alebo Barcode (as_scan;dodaci_list;2021001179) [null]

        if ((typeof msg === 'string') && msg.indexOf('Barcode') === 0 && msg.indexOf('[label') !== -1) {
            msg = msg.toLowerCase();
            state.datawedge.scannedBarcode = {
                _data: msg,
                code: msg.substr(9, msg.indexOf('[label') - 11),
                type: msg.substr(msg.indexOf('[label-type-') + "[label-type-".length).replace(']', '')
            }
        }else if ((typeof msg === 'string') && msg.indexOf('Barcode') === 0) {
            msg = msg.toLowerCase();
            const code = msg.substr(msg.indexOf('(') + 1 , msg.indexOf(')') - msg.indexOf('(') - 1);
            state.datawedge.scannedBarcode = {
                _data: msg,
                code: code,
                type: 'multi',
                codes: code.split('#').filter(i => i.length > 0)
            }
        }
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
        console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
        state.datawedge.reconnectError = true;
    },
}

const actions = {}

const getters = {
    isWsConnected: (state) => {
        return state.datawedge.isConnected
    },
    scannedBarcode: (state) => {
        return state.datawedge.scannedBarcode
    },
    latestMessage: (state) => {
        return state.datawedge.latestMessage
    }
}

export {
    actions,
    state,
    mutations,
    getters
}
