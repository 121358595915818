import Vue from 'vue'
import Vuex from 'vuex'
import {
    actions as AuthActions,
    mutations as AuthMutations,
    state as AuthState,
    getters as AuthGetters
} from "@/store/_auth";
import {
    actions as WsActions,
    mutations as WsMutations,
    state as WsState,
    getters as WsGetters
} from "@/store/_datawedge_ws";

Vue.use(Vuex);

export default new Vuex.Store({
    state: Object.assign({
        navbarVisible: true,
        notifications: []
    }, AuthState, {datawedge: WsState}),
    mutations: Object.assign({
        hideNavbar(state: { [k: string]: any }) {
            if (state.navbarVisible) {
                state.navbarVisible = false;
            }
        },
        showNavbar(state: { [k: string]: any }) {
            if (!state.navbarVisible) {
                state.navbarVisible = true;
            }
        },
        addNotification(state: { [k: string]: any }, notification: { [k: string]: any }) {
            if (notification.id !== undefined && notification.id.length > 0 && !state.notifications.some((item: { id: string }) => {
                return item.id === notification.id;
            })) {
                state.notifications.push(notification);
            }
        },
        clearNotifications(state: { [k: string]: any }) {
            state.notifications = [];
        },
        removeNotification(state: { [k: string]: any }, id: string) {
            state.notifications = state.notifications.map((notification: { id: string, hidden: boolean }) => {
                if (notification.id === id) {
                    notification.hidden = true;
                }
                return notification;
            });
        }
    }, AuthMutations, WsMutations),
    actions: Object.assign({}, AuthActions, WsActions),
    getters: Object.assign({
        activeNotifications(state: { [k: string]: any }) {
            return state.notifications.filter((notification: { [k: string]: any }) => {
                return notification.hidden === false;
            })
        }
    }, AuthGetters, WsGetters),
    modules: {}
})
