import {Component, Vue} from "vue-property-decorator";

interface ISettingEnum {
    defaultLoginPage: string,
    pasteScanner: boolean,
    deliveryNoteNotification: boolean,
}

@Component
export default class SettingMixin extends Vue {

    storedSettings(): ISettingEnum {
        const defaultSettings = {defaultLoginPage: '/admin', pasteScanner: false};
        let localSettings: { [k: string]: any } | string | null = localStorage.getItem('app-settings');

        if (typeof localSettings === 'string') {
            localSettings = JSON.parse(localSettings) as { [k: string]: any };
            localSettings = {...defaultSettings, ...localSettings};
        } else {
            localSettings = defaultSettings;
        }
        return localSettings as ISettingEnum;
    }

}
