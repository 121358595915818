
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import BarcodeScanner from '@/components/barcodeScanner.vue';
import {mixins} from "vue-class-component";
import navbarMixin from "@/mixins/navbarMixin";

@Component({
  components: {
    BarcodeScanner
  }
})
export default class Home extends mixins(navbarMixin) {
  @Prop() private msg!: string;
  showScanner = false
  test: string = "";

/*
  @Watch('latestDataWedgeScanCode')
  onChildChanged(val: { code: string, type: string, codes: string[] }, oldVal: { code: string, type: string }) {
    // @ts-ignore
    let batch = val.type = 'multi' && val.codes && val.codes.length === 2 ? val.codes.find(i => i.indexOf('batch') === 0) : undefined;
    // @ts-ignore
    batch = batch !== undefined ? batch.replace('batch', '') : batch;
    // @ts-ignore
    // const code = val.type = 'multi' && val.codes.length === 2 ? val.codes.find(i => i.indexOf('batch') !== 0) : val.code;

    this.test = val.code;
  }


  get latestDataWedgeScanCode() {
    return this.$store.getters.scannedBarcode;
  }*/
}
