<template>
  <b-navbar toggleable="lg" variant="base" id="navbar" :hidden="!showNavbar">
    <b-navbar-brand :to="homepageLink">
      <img src="../assets/img/logo-f.png" class="d-inline-block align-top" alt="Kitten">
    </b-navbar-brand>

    <div class="phone-icons d-sm-none">
      <a href="#" class="small-top-icon" :class="{'active' : hasNotification}"
         @click="showNotifications">
        <fa-icon :icon="[hasNotification ? 'fas' : 'far', 'bell']"/>
        <span class="badge badge-danger" v-if="hasNotification">
            {{ notificationCount }}
        </span>
      </a>
      <router-link to="/admin/user" class="small-top-icon">
        <fa-icon :icon="['far', 'user']"/>
      </router-link>
    </div>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/models" class="square">
          Modely pneu.
        </b-nav-item>
        <template v-if="isUserLoggedIn()">

          <b-nav-item to="/admin" class="square">Administrácia</b-nav-item>
          <b-nav-item to="/admin/performance" class="square"
                      v-if="getLoggedUser().isAllowedTo('storageAccess')">
            Výkon
          </b-nav-item>
          <b-nav-item to="/admin/stock" class="square"
                      v-if="getLoggedUser().isAllowedTo('storageAccess')">
            Sklad
          </b-nav-item>
          <b-nav-item to="/admin/prepravko" class="square"
                      v-if="getLoggedUser().isAllowedTo('prepravkoAccess')">
            Prepravko
          </b-nav-item>
          <li class="nav-item d-md-none">
            <hr/>
          </li>
          <b-nav-item to="/admin/user/settings" class="d-md-none">
            <fa-icon icon="cog"/>&nbsp;Nastavenia
          </b-nav-item>
          <b-nav-item to="/admin/user/profil" class="d-md-none">
            <fa-icon :icon="['far', 'user']"/>&nbsp;Profil
          </b-nav-item>
          <b-nav-item @click="logout" class="d-md-none">
            <fa-icon icon="power-off"/>&nbsp;Odhlásiť sa
          </b-nav-item>

        </template>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown text="Jazyk" right class="d-none">
          <b-dropdown-item href="#">SK</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right v-if="isUserLoggedIn()" class="d-none d-md-block">
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <em>{{ getLoggedUser().name }}</em>
          </template>
          <b-dropdown-item to="/admin/user/profil">
            <fa-icon :icon="['far', 'user']"/>&nbsp;Profil
          </b-dropdown-item>
          <b-dropdown-item to="/admin/user/settings">
            <fa-icon icon="cog"/>&nbsp;Nastavenia
          </b-dropdown-item>
          <b-dropdown-item @click="logout">
            <fa-icon icon="power-off"/>&nbsp;Odlásiť sa
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-dropdown-item v-else to="/admin/login">
          <fa-icon :icon="['far','user']"/>&nbsp;Prihlásenie
        </b-dropdown-item>
      </b-navbar-nav>


      <b-modal id="notificationsModal" title="Notifikácie" ref="notificationsModal" :hide-footer="true">
        <ul class="list-group">
          <li class="list-group-item" v-for="notification in notifications">
            <p>
              <span
                  @click="removeNotification(notification); hideNotifications(); redirectToNotification(notification)">
                {{ notification.title }}
              </span>
              <small v-if="notification.redirect === $route.fullPath" class="d-block">
                ( Aktualne otvorene )
              </small>

              <button class="btn btn-danger float-right btn-xs" @click="removeNotification(notification)">
                <fa-icon :icon="['fas','trash']"/>
              </button>
            </p>
            <small>{{ notification.date }}</small>
          </li>
        </ul>
        <br>
        <div class="row">
          <div class="col-6">
            <button class="btn btn-outline-danger btn-md" @click="clearNotifications(); hideNotifications();">
              Zmazať všetky
            </button>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-success btn-md" @click="hideNotifications">
              Zatvoriť
            </button>
          </div>
        </div>
      </b-modal>
    </b-collapse>
  </b-navbar>
</template>

<script>

import authMixin from "@/mixins/authMixin";
import {Howl} from "howler";
import SettingMixin from "@/mixins/settingMixin";

export default {
  name: "navbar",
  mixins: [authMixin, SettingMixin],
  computed: {
    showNavbar: function () {
      return this.$store.state.navbarVisible;
    },
    hasNotification: function () {
      return this.notifications.length > 0;
    },
    notificationCount: function () {
      return this.notifications.length;
    },
    notifications: function () {
      return this.$store.getters.activeNotifications.reverse();
    },
    homepageLink: function () {
      return (`/${this.storedSettings().defaultLoginPage}`).replace('//', '/');
    }
  },
  watch: {
    notifications: (newVal, oldVal) => {
      if (newVal.length > oldVal.length) {
        const sound = new Howl({
          src: [require('@/assets/sound/bell_3.mp3')]
        });
        sound.volume(1);
        sound.play();
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('AUTH_LOGOUT', {}).then(() => {
        window.location.href = "/";
      });
    },
    showNotifications() {
      this.$refs['notificationsModal'].show();
    },
    hideNotifications() {
      this.$refs['notificationsModal'].hide();
    },
    removeNotification(notification) {
      this.$store.commit('removeNotification', notification.id);
    },
    redirectToNotification(notification) {
      this.$router.push({'path': notification.redirect});
    },
    clearNotifications(notification) {
      this.$store.commit('clearNotifications');
    }
  }
}
</script>
