import BaseModel from "./BaseModel";

export default class User extends BaseModel {
    private _id: number;
    private _name: string;
    private _email: string;
    private _category: string;
    private _permissions: Array<string> = [];


    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get category(): string {
        return this._category;
    }

    set category(value: string) {
        this._category = value;
    }


    get permissions(): Array<string> {
        return this._permissions;
    }

    set permissions(value: Array<string>) {
        this._permissions = typeof value === 'string' ? JSON.parse(value) : value;
    }

    public isAllowedTo(permission: string) {
        return this.permissions.find(p => p === permission) !== undefined;
    }
}
