import PageNotFound from "@/views/404.vue";

export const menuItems = [
    {path: '', component: () => import('../views/admin/Stock.vue')},
    {path: 'in', component: () => import('../views/admin/stock/In.vue')},
    {path: 'in/:id', component: () => import('../views/admin/stock/InWithId.vue')},
    {path: 'out', component: () => import('../views/admin/stock/Out.vue')},
    {path: 'out-position', component: () => import('../views/admin/PositionOut.vue')},
    {path: 'out-position-clear', component: () => import('../views/admin/PositionOutNoDoc.vue')},
    {path: 'in-position-clear', component: () => import('../views/admin/PositionInNoDoc.vue')},
    {path: 'out/:id', component: () => import('../views/admin/stock/OutWithId.vue')},
    {path: 'search', component: () => import('../views/admin/stock/Search.vue')},
    {path: 'positions', component: () => import('../views/admin/Positions.vue')},
    {path: 'positions/scan', component: () => import('../views/admin/positions/Scan.vue')},
    {path: 'positions/delete', component: () => import('../views/admin/positions/Clear.vue')},
    {path: 'inventory', component: () => import('../views/admin/Inventory.vue')},
    {path: 'stocktaking', component: () => import('../views/admin/Stocktaking.vue')},
    {path: 'export-delivery', component: () => import('../views/admin/ExportDelivery.vue')},
    {path: 'export-delivery/new', component: () => import('../views/admin/exportDelivery/Edit.vue')},
    {path: 'export-delivery/:id', component: () => import('../views/admin/exportDelivery/Edit.vue')},
    {path: 'storage-movement', component: () => import('../views/admin/StorageMovement.vue')},
    {path: 'inventory/new', component: () => import('../views/admin/inventory/Edit.vue')},
    {path: 'inventory/:id', component: () => import('../views/admin/inventory/Edit.vue')},
    {path: 'stats/same_codes', component: () => import('../views/admin/stock/stats/SameCodes.vue')},

    {
        path: '*',
        name: '404',
        component: PageNotFound
    },
]
