// @ts-nocheck
import axios, {AxiosResponse} from "axios";
import {TyrionApiResponse} from "@/lib/interfaces/TyrionApiResponse";
import User from "@/lib/models/User";


const state = {
    token: localStorage.getItem('user-token') || "",
    prepravkoToken: localStorage.getItem('user-prepravko-token') || "",
    userData: () => {
        try {
            return (new User()).fromObject(JSON.parse(typeof localStorage.getItem('user-data') === "string" ? String(localStorage.getItem('user-data')) : "{}")) as User
        } catch (e) {
            return new User();
        }
    },
    status: "",
};

const mutations = {
    AUTH_REQUEST: (state) => {
        state.status = 'loading'
    },
    AUTH_LOGOUT: (state) => {
        state.status = 'loading'
    },
    AUTH_SUCCESS: (state, token) => {
        state.status = 'success';
        state.token = token;
    },
    PREPRAVKO_SET: (state, prepravkoToken) => {
        state.prepravkoToken = prepravkoToken;
    },
    AUTH_ERROR: (state) => {
        state.status = 'error'
    },
}

const actions = {
    AUTH_REQUEST: ({commit, dispatch}, user) => {
        return new Promise((resolve, reject) => { // The Promise used for router redirect in login
            commit('AUTH_REQUEST');

            axios.post(process.env.VUE_APP_API_URL + '/login_user', user, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Authentication": `token:${process.env.VUE_APP_API_TOKEN}${Math.round(+new Date() / 1000)}000$g2${Math.floor((Math.random() * 2222) + 2222)}000$g2`
                }
            }).then((resp: AxiosResponse<TyrionApiResponse>) => {
                if (resp.data.status === 'success') {
                    const token = resp.data.data.token;
                    const prepravkoToken = resp.data.data.prepravkoToken;
                    localStorage.setItem('user-token', token);
                    localStorage.setItem('user-prepravko-token', prepravkoToken);
                    localStorage.setItem('user-data', JSON.stringify(resp.data.data.user));
                    commit('AUTH_SUCCESS', token);
                    commit('PREPRAVKO_SET', prepravkoToken);
                    resolve(resp);
                } else {
                    commit('AUTH_ERROR', resp.data.message);
                    localStorage.removeItem('user-token');
                    localStorage.removeItem('user-prepravko-token');
                    reject(resp.data.message)
                }
            }).catch((err: any) => {
                commit('AUTH_ERROR', err);
                localStorage.removeItem('user-token');
                localStorage.removeItem('user-prepravko-token');
                reject((err.response && err.response.data && err.response.data.message) ? err.response.data.message : err.message);
            });
        })
    }, AUTH_LOGOUT: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit('AUTH_LOGOUT')
            localStorage.removeItem('user-token');
            localStorage.removeItem('user-data');
            localStorage.removeItem('user-prepravko-token');
            resolve()
        })
    }
}
const getters = {
    isAuthenticated: (state) => {
        return state.token && state.userData().id > 0
    },
    authStatus: state => state.status,
}

export {
    actions,
    state,
    mutations,
    getters
}
