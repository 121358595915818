
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import navbarMixin from "@/mixins/navbarMixin";
import settingMixin from "@/mixins/settingMixin";
import tyrionApi from "@/mixins/tyrionApi";

@Component({})
export default class Admin extends mixins(navbarMixin, settingMixin, tyrionApi) {

  private testInput: string = '';

  mounted() {
    const settings = this.storedSettings();
    if (settings.deliveryNoteNotification) {
      setInterval(() => {
        this.callTyrionUrl('/notifications').then((data) => {
          this.$store.commit('addNotification', data.data.data);
        }).catch((e) => {
          this.$store.commit('clearNotifications', null)
        })
      }, 5000);
    }
  }

  @Watch('latestDataWedgeScanCode')
  onChildChanged(val: { code: string, codes: Array<string>, type: string }, oldVal: {
    code: string,
    codes: Array<string>,
    type: string
  }) {

    if (oldVal != val) {
      try {
        const cVal = JSON.parse(val.code) as { action: string, params: { [k: string]: any } };
        if (cVal && cVal.action !== undefined) {
          this.switchScannerFastAction(cVal.action, cVal.params);
        }
      } catch (e) {

      }

    }
  }

  get latestDataWedgeScanCode() {
    return this.$store.getters.scannedBarcode;
  }

  public test() {
    this.switchScannerFastAction('outPositionClear', {position: 'R14b'});
  }

  private switchScannerFastAction(action: string, params: { [p: string]: any }) {
    switch (action.toLowerCase()) {
      case "outpositionclear":
        this.$router.push({path: '/admin/stock/out-position-clear', query: {position: params.position}});
        break;
      case "inpositionclear":
        this.$router.push({path: '/admin/stock/in-position-clear', query: {position: params.position}});
        break;
    }
  }
}
