import Vue from 'vue'
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import PageNotFound from "@/views/404.vue";
import AdminComponent from "@/views/Admin.vue";
import AdminStockComponent from "@/views/admin/Home.vue";
import axios from "axios";
import store from '../store';
import {menuItems as adminMenuItems} from "./admin";
import {menuItems as stockMenuItems} from "./stock";
import {menuItems as prepravkoMenuItems} from "./prepravko";

Vue.use(VueRouter);


const ifNotAuthenticated = (to: Route, from: Route, next: NavigationGuardNext) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next('/');
}

const ifNotAuthenticatedAdmin = (to: Route, from: Route, next: NavigationGuardNext) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next('/admin');
}

function validateLoggedUser() {
    return axios.post(process.env.VUE_APP_API_URL + '/verify_user', {}, {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Authentication": `token:${process.env.VUE_APP_API_TOKEN}${Math.round(+new Date() / 1000)}000$g2${Math.floor((Math.random() * 2222) + 2222)}000$g2${store.state.token}`
        }
    }).then((res) => {
        return res.data && res.data.status === 'success' ? res.data : false;
    }).catch((e) => {
        return false;
    })
}

const ifAuthenticated = async (to: Route, from: Route, next: NavigationGuardNext, routePermission: string | null = null) => {
    if (store.getters.isAuthenticated) {

        validateLoggedUser().then((res: false | { data: { [k: string]: any }, status: string }) => {
            if (typeof res === "object" && res.status === 'success') {
                const userPermissions = JSON.parse(res.data.permissions);
                if (routePermission === null || userPermissions.some((p: string) => p === routePermission)) {
                    next();
                    return;
                } else {
                    next('/login');
                    localStorage.removeItem('user-token');
                }
            } else {
                localStorage.removeItem('user-token');
            }
        })

    } else {
        next('/login');
    }
}



const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Domov',
        component: Home
    },
    {
        path: '/models',
        name: 'Modely',
        component: () => import('../views/TyreModels.vue')
    },
    {
        path: '/login',
        name: 'Prihlasenie',
        component: Login,
        beforeEnter: ifNotAuthenticatedAdmin,
    },
    {
        path: '/model/:model',
        name: 'Model info',
        component: () => import('../views/TyreModel.vue')
    },
    {
        path: '/admin/stock',
        component: AdminComponent,
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            return ifAuthenticated(to, from, next, 'storageAccess')
        },
        children: stockMenuItems
    },
    {
        path: '/admin/prepravko',
        component: AdminComponent,
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            return ifAuthenticated(to, from, next, 'prepravkoAccess')
        },
        children: prepravkoMenuItems
    },
    {
        path: '/admin',
        component: AdminComponent,
        beforeEnter: ifAuthenticated,
        children: adminMenuItems
    },
    {
        path: 'login', component: () => import('../views/Login.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '*',
        name: '404',
        component: PageNotFound
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
