
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import navbarMixin from "@/mixins/navbarMixin";

    @Component
    export default class Login extends mixins(navbarMixin) {
        @Prop() private msg!: string;

        email: string = '';
        password: string = '';
        errorMessage: string = '';

        get isLoading() {
            return this.$store.getters.authStatus === 'loading';
        }

        loginUser() {

            this.errorMessage = "";
            this.$store.dispatch('AUTH_REQUEST', {email: this.email, password: this.password}).then(() => {
                this.$router.push('/admin')
            }).catch(e => {
                this.errorMessage = e;
            });

        }
    }
