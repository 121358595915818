import PageNotFound from "@/views/404.vue";

export const menuItems = [
    {path: '', component: () => import('../views/admin/Prepravko.vue')},
    {path: 'in', component: () => import('../views/admin/prepravko/PrepravkoIn.vue')},
    {path: 'zvoz_in', component: () => import('../views/admin/prepravko/ZvozIn.vue')},
    {path: 'zvoz_out', component: () => import('../views/admin/prepravko/ZvozOut.vue')},
    {path: 'other', component: () => import('../views/admin/prepravko/OtherActions.vue')},
    {path: 'out', component: () => import('../views/admin/prepravko/PrepravkoOut.vue')},
    {
        path: '*',
        name: '404',
        component: PageNotFound
    },
]
