import {Component, Vue} from "vue-property-decorator";
import User from "@/lib/models/User";

@Component
export default class AuthMixin extends Vue {

    isUserLoggedIn() {
        return this.$store.state.token && this.getLoggedUser().id > 0;
    }

    getLoggedUser(): User {
        return this.$store.state.userData();
    }

}
